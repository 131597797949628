<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Add Criteria</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <label for="name" class="col-md-3 form-label">
                  Criteria Name
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Criteria Name"
                    v-model="criteria.name"
                    :class="{
                      'p-invalid': v$.criteria.name.$error || errorFor('name'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.criteria.name.$errors,
                      value: 'Criteria Name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Evaluating Criteria
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <v-select
                    class="custom-selectCSS"
                    v-model="criteria.evaluating_criteria_id"
                    label="name"
                    :options="evaluatingCriteria"
                    :clearable="false"
                    :reduce="(name) => name.id"
                    :class="{
                      'p-invalid':
                        v$.criteria.evaluating_criteria_id.$error ||
                        errorFor('evaluating_criteria_id'),
                    }"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('evaluating_criteria_id')"
                    :vuelidateErrors="{
                      errors: v$.criteria.evaluating_criteria_id.$errors,
                      value: 'Evaluating Criteria',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <label for="lowest_score" class="col-md-3 form-label">
                  Lowest Score
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <input
                    type="number"
                    id="lowest_score"
                    name="lowest_score"
                    class="form-control"
                    @input="lowScoreChanges($event.target.value)"
                    placeholder="Lowest Score"
                    v-model="criteria.lowest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.lowest_score.$error ||
                        errorFor('lowest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('lowest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.lowest_score.$errors,
                      value: 'Lowest Score',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <label for="highest_score" class="col-md-3 form-label">
                  Highest Score
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <input
                    type="number"
                    id="highest_score"
                    name="highest_score"
                    class="form-control"
                    @input="highScoresChanges($event.target.value)"
                    placeholder="Highest Score"
                    v-model="criteria.highest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.highest_score.$error ||
                        errorFor('highest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('highest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.highest_score.$errors,
                      value: 'Highest Score',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div
                class="row mb-3"
                v-if="
                  currentRouteName === 'trainer-orientation-criteria-update'
                "
              >
                <label for="status" class="col-md-4 form-label">
                  Status
                  <!-- <span class="text-danger">*</span> -->
                  <!-- :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      " -->
                </label>
                <div class="col-md-6">
                  <select name="status" v-model="status" class="form-control">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3" v-if="status == 0">
                <label for="remark" class="col-md-4 form-label">
                  Remark
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div class="col-md-6">
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="remark"
                    :class="{
                      'p-invalid': v$.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-orientation-criteria' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-orientation-criteria-update'
                          ? updateCriteria()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      criteria: {
        name: "",
        evaluating_criteria_id: "",
        lowest_score: "",
        highest_score: "",
      },
      evaluatingCriteria: [],
      status: "1",
      remark: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      criteria: {
        name: { required },
        evaluating_criteria_id: { required },
        lowest_score: { required },
        highest_score: { required },
      },
      remark: {
        required: requiredIf(() => {
          return this.status != 1;
        }),
      },
    };
  },
  methods: {
    lowScoreChanges(val) {
      this.criteria.lowest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.lowest_score;
    },
    highScoresChanges(val) {
      this.criteria.highest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.highest_score;
    },
    async getAllEvaluatingCriteria() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .post(`${this.baseUrl}admin/v1/get-evaluating-criteria`)
        .then((response) => {
          this.evaluatingCriteria = response.data.data.filter(function (val) {
            //for active Evaluating Criteria
            if (val.status == 1 && val.type == "Orientation") {
              return {
                id: val.id,
                level: val.level,
                name: val.name,
                status: val.status,
                type: val.type,
              }; 
            }
          });
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getCriteriaById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/training-orientation-criteria/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.criteria = result;
          this.criteria.name = result.name;
          this.criteria.evaluating_criteria_id = result.evaluating_criteria_id;
          this.criteria.lowest_score = result.lowest_score;
          this.criteria.highest_score = result.highest_score;
          this.status = result.status;
          this.remark = result.remark;
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
      this.loading = false;
    },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      await axios
        .post(
          `${this.baseUrl}admin/v1/training-orientation-criteria`,
          this.criteria
        )
        .then(() => {
          this.$router.push({ name: "trainer-orientation-criteria" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      let data = {
        name: this.criteria.name,
        evaluating_criteria_id: this.criteria.evaluating_criteria_id,
        lowest_score: this.criteria.lowest_score,
        highest_score: this.criteria.highest_score,
        status: this.status,
        remark: this.remark,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v1/training-orientation-criteria/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "trainer-orientation-criteria" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getAllEvaluatingCriteria();
    if (this.currentRouteName == "trainer-orientation-criteria-update") {
      this.getCriteriaById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
